@keyframes pressEffect {
    0% {
        box-shadow: 5px 5px 10px var(--shadow-outer),
        -5px -5px 10px var(--directional-light-color);
        opacity: 1;
    }
    50% {
        box-shadow: 2px 2px 5px var(--shadow-outer),
        -2px -2px 5px var(--directional-light-color);
        opacity: 0.5;
    }
    100% {
        box-shadow: inset 5px 5px 10px var(--shadow-outer),
        inset -5px -5px 10px var(--directional-light-color);
        opacity: 1;
    }
}

@keyframes releaseEffect {
    0% {
        box-shadow: inset 5px 5px 10px var(--shadow-outer),
        inset -5px -5px 10px var(--directional-light-color);
        opacity: 1;
    }
    50% {
        box-shadow: 2px 2px 5px var(--shadow-outer),
        -2px -2px 5px var(--directional-light-color);
        opacity: 0.5;
    }
    100% {
        box-shadow: 5px 5px 10px var(--shadow-outer),
        -5px -5px 10px var(--directional-light-color);
        opacity: 1;
    }
}



.neumorphism-flat {
    box-shadow: 5px 5px 10px var(--shadow-outer),
    -5px -5px 10px var(--directional-light-color);
    animation: releaseEffect 0.2s ease-in;
}

.neumorphism-pressed {
    box-shadow: inset 5px 5px 10px var(--shadow-outer),
    inset -5px -5px 10px var(--directional-light-color);
    animation: pressEffect 0.2s ease-out;
}

.neumorphism-flat.active {
    box-shadow: inset 5px 5px 10px var(--shadow-outer),
    inset -5px -5px 10px var(--directional-light-color);
}

.neumorphism-flat-hover:hover {
    box-shadow: inset 5px 5px 10px var(--shadow-outer),
    inset -5px -5px 10px var(--directional-light-color);
}


.neumorphism-convex {
    background: var(--light-color);
    box-shadow: 5px 5px 10px var(--shadow-outer),
    -5px -5px 10px var(--directional-light-color);
}

.neumorphism-convex-hover:hover {
    box-shadow: inset 5px 5px 10px var(--shadow-outer),
    inset -5px -5px 10px var(--directional-light-color);
}

.neumorphism-concave {
    background: var(--dark-light-gradient);
    box-shadow: 5px 5px 10px var(--shadow-inner),
    -5px -5px 10px var(--directional-light-color);
}

.neumorphism-concave-hover:hover {
    box-shadow: 5px 5px 10px var(--shadow-outer),
    -5px -5px 10px var(--directional-light-color);
}

.neumorphism-text {
    color: var(--background-color);
    text-shadow: 2px 0 2px var(--directional-light-color), 0 2px 2px var(--dark-color);
}