.btn-base {
  height: 40px;
  --bs-btn-border-radius: 20px;
}

.btn-regular {
 @extend .btn-base;
  --bs-btn-color: var(--secondary-blue);
  --bs-btn-bg: var(--white);
  --bs-btn-border-color: var(--secondary-blue);
  --bs-btn-hover-color: var(--secondary-blue);
  --bs-btn-hover-bg: var(--tertiary-blue);
  --bs-btn-hover-border-color: var(--primary-blue);
  --bs-btn-disabled-color: var(--dark-gray);
  --bs-btn-disabled-bg: var(--light-gray);
  --bs-btn-disabled-border-color: var(--dark-gray);
}

.btn-regular-active {
  @extend .btn-regular;
  --bs-btn-bg: var(--tertiary-blue);
}

.btn-regular-dark {
  @extend .btn-base;
  --bs-btn-color: var(--secondary-blue);
  --bs-btn-bg: var(--light-gray);
  --bs-btn-border-color: var(--secondary-blue);
  --bs-btn-hover-color: var(--secondary-blue);
  --bs-btn-hover-bg: var(--tertiary-blue);
  --bs-btn-hover-border-color: var(--primary-blue);
  --bs-btn-disabled-color: var(--dark-gray);
  --bs-btn-disabled-bg: var(--light-gray);
  --bs-btn-disabled-border-color: var(--dark-gray);
}


.btn-regular-full {
  @extend .btn-regular;
  display: block;
  width: 100%;
}

.btn-regular-disabled {
  @extend .btn-base;
  --bs-btn-color: var(--dark-gray);
  --bs-btn-bg: var(--white);
  --bs-btn-border-color: var(--dark-gray);
  --bs-btn-hover-border-color: var(--dark-gray);
  --bs-btn-hover-bg: var(--tertiary-blue);
}

.btn-alert {
  @extend .btn-base;
  --bs-btn-color: var(--primary-danger);
  --bs-btn-bg: var(--white);
  --bs-btn-border-color: var(--primary-danger);
  --bs-btn-hover-color: var(--primary-danger);
  --bs-btn-hover-bg: var(--secondary-danger);
  --bs-btn-hover-border-color: var(--primary-danger);
  --bs-btn-disabled-color: var(--dark-gray);
  --bs-btn-disabled-bg: var(--light-gray);
  --bs-btn-disabled-border-color: var(--dark-gray);
}

.btn-alert-full {
  @extend .btn-alert;
  width: 100%;
}
