@import "buttons";
@import "variables";
@import url("public/fa/css/all.css");
@import url('./neumorphism.css');

html, body{
  overflow: hidden;
  touch-action: none;
  -webkit-overflow-scrolling: auto; /* Disables momentum scroll */
  overscroll-behavior: none;
}

.native-interaction {
  touch-action: none;
  -webkit-overflow-scrolling: auto; /* Disables momentum scroll */
  overscroll-behavior: none;
}

body {
  margin: 0;
  font-family: 'Josefin Sans', sans-serif;
  font-size: var(--fs-md);
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.z-max {
  z-index: 2147483647;
}

.z-min {
  z-index: -2147483647;
}

.width-100 {
  width: 100px;
}

.height-220 {
  height: 220px;
}

.height-90 {
  height: 90px;
}

.width-90 {
  width: 90px;
}

.min-height-220 {
  min-height: 220px;
}

.min-height-300 {
  min-height: 300px;
}

.min-width-220 {
  min-width: 220px;
}

.min-width-280 {
  min-width: 280px;
}

.max-width-400 {
  max-width: 400px;
}

.max-width-300 {
  max-width: 300px;
}

.max-height-40 {
  max-height: 40px;
}

.max-height-50 {
  max-height: 50px;
}

.max-height-200 {
  max-height: 200px;
}

.max-height-400 {
  max-height: 400px;
}

.vw-100-p-5 {
  width: calc(100vw - 3em);
}

.vh-100-offset-190px {
  height: calc(100vh - 190px);
}

.vh-100-offset-300px {
  height: calc(100vh - 300px);
}

.greyed-out {
  color: rgb(128, 128, 128);
}

.width-150 {
  width: 150px;
}
.width-200 {
  width: 200px;
}
.width-300 {
  width: 300px;
}

.width-400 {
  width: 400px;
}

.width-275 {
  width: 275px;
}

.max-width-1000 {
  max-width: 1000px;
}

.max-width-200 {
  max-width: 200px;
}

.max-width-400 {
  max-width: 400px;
}

.max-width-300 {
  max-width: 300px;
}

.width-36 {
  width: 36px;
}

.width-40 {
  width: 40px;
}

.margin-bottom-90px {
  margin-bottom: 90px
}

.margin-bottom-200px {
  margin-bottom: 200px
}

.margin-top-90px {
  margin-top: 90px
}

.margin-top-50px {
  margin-top: 50px
}

.height-36 {
  height: 36px;
}


.height-40 {
  height: 40px;
}

.min-width-100 {
  min-width: 110px;
}


.min-width-300 {
  min-width: 300px;
}

.min-width-400 {
  min-width: 400px;
}

.max-width-600 {
  max-width: 600px;
}

.min-height-50 {
  min-height: 50px;
}

#avatar-input-file {
  display: none;
}

.custom-file-upload {
  margin: auto;
  border-radius: 100px;
  cursor: pointer;
}


.ng-invalid {
  border-color: var(--primary-pink) !important;
}

input {
  color: var(--primary-blue) !important;
  caret-color: var(--primary-blue) !important;
}

.color-primary-danger {
  color: var(--primary-danger);
}

.color-primary-warn {
  color: var(--primary-warn);
}

.color-primary-blue {
  color: var(--primary-blue);
}

.color-secondary-blue {
  color: var(--secondary-blue);
}

.color-nominal {
  color: var(--nominal);
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card {
  border: none;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-modal {
  padding: 10px;
  border-radius: 20px;
}

.modal-content {
  border-radius: 20px !important;
}

a {
  text-decoration: none;
  color: var(--secondary-blue) !important;
}

a:hover {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.pointer-hover:hover {
  background-color: var(--secondary-blue) !important;
  color: var(--white) !important;
}

.background-color-secondary-blue {
  background-color: var(--secondary-blue) !important
}

.background-color-white {
  background-color: var(--white) !important
}

.background-color-light-gray {
  background-color: var(--light-gray) !important
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.padded-modal {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.top-0-safe-area-inset {
  top: var(--safe-area-inset-statusbar);
}

.vh-100-safe-area-inset-top {
  height: calc(100vh - var(--safe-area-inset-statusbar) - var(--safe-area-inset-top)) !important;
  background-color: var(--white-color);
  top: var(--safe-area-inset-statusbar);
}

.vh-100-safe-area-inset {
  height: calc(100vh - var(--safe-area-inset-statusbar) - var(--safe-area-inset-top)) !important;
  background-color: var(--white-color);
  top: var(--safe-area-inset-statusbar);
  bottom: calc(var(--safe-area-inset-bottom)) !important;
}